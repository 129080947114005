<template>
  <a-drawer
    :destroy-on-close="true"
    :mask-closable="false"
    :title="isUpdate ? '文档编辑' : '文档创建'"
    :visible="visible"
    :width="800"
    @close="closeDrawer"
  >
    <a-form-model
      ref="form"
      :model="form"
      :rules="rules"
    >
      <a-form-model-item
        label="名称"
        :label-col="labelCol"
        prop="name"
        :wrapper-col="wrapperCol"
      >
        <a-input v-model="form.name" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item
        label="描述"
        :label-col="labelCol"
        prop="description"
        :wrapper-col="wrapperCol"
      >
        <a-textarea v-model="form.description" :max-length="255"></a-textarea>
      </a-form-model-item>
      <a-divider></a-divider>
      <a-form-model-item prop="content">
        <Toolbar
          :default-config="toolbarConfig"
          :editor="editor"
          style="border-bottom: 1px solid #ccc"
        ></Toolbar>
        <Editor
          :default-config="editorConfig"
          @onChange="onChange"
          @onCreated="onCreated"
          style="height: 480px; overflow-y: hidden"
        ></Editor>
      </a-form-model-item>
    </a-form-model>
    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 1,
      }"
    >
      <a-space size="middle">
        <a-button @click="closeDrawer">取消</a-button>
        <a-button :loading="loading" type="primary" @click="ok">
          确定
        </a-button>
      </a-space>
    </div>
  </a-drawer>
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import '@wangeditor/editor/dist/css/style.css'

import {
  createDocument,
  getDocument,
  updateDocument,
  uploadDocumentImage
} from '@/api/document'

export default {
  name: 'InputDrawer',
  components: {
    Editor,
    Toolbar
  },
  data () {
    return {
      isUpdate: false,
      visible: false,
      form: {
        id: '',
        name: '',
        description: '',
        content: ''
      },
      rules: {
        name: [
          {
            message: '请输入名称',
            required: true,
            trigger: 'blur'
          }
        ],
        content: [
          {
            message: '请输入内容',
            required: true,
            trigger: 'blur'
          }
        ]
      },
      labelCol: {
        span: 2,
        style: {
          textAlign: 'left'
        }
      },
      wrapperCol: {
        span: 22
      },
      toolbarConfig: {
        excludeKeys: ['fullScreen', 'insertImage', 'group-video']
      },
      editorConfig: {
        placeholder: '请输入内容 ...',
        MENU_CONF: {
          uploadImage: {
            async customUpload (file, insertFn) {
              const params = new FormData()
              params.append('file', file)
              uploadDocumentImage(params).then(res => {
                const data = res.data
                insertFn(data.url, data.alt, data.href)
              })
            }
          }
        }
      },
      editor: null,
      loading: false
    }
  },
  methods: {
    show (id) {
      if (id) {
        this.isUpdate = true
        getDocument(id)
          .then(res => {
            const data = res.data
            this.form = {
              id: data.id,
              name: data.name,
              description: data.description,
              content: data.content
            }
          })
          .finally(() => {
            this.visible = true
          })
      } else {
        this.isUpdate = false
        this.visible = true
      }
    },
    closeDrawer () {
      this.form = {
        id: '',
        name: '',
        description: '',
        content: ''
      }
      this.$refs.form.resetFields()
      this.visible = false
    },
    onChange (editor) {
      this.form.content = editor.getHtml()
    },
    onCreated (editor) {
      this.editor = Object.seal(editor)
      if (this.form.content) this.editor.setHtml(this.form.content)
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const params = {
            name: this.form.name,
            description: this.form.description,
            content: this.form.content
          }
          this.loading = true
          if (this.isUpdate) {
            updateDocument(this.form.id, params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.closeDrawer()
              })
              .finally(() => {
                this.loading = false
              })
          } else {
            createDocument(params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.closeDrawer()
              })
              .finally(() => {
                this.loading = false
              })
          }
        }
      })
    }
  }
}
</script>
