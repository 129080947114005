var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-drawer',{attrs:{"destroy-on-close":true,"mask-closable":false,"title":_vm.isUpdate ? '文档编辑' : '文档创建',"visible":_vm.visible,"width":800},on:{"close":_vm.closeDrawer}},[_c('a-form-model',{ref:"form",attrs:{"model":_vm.form,"rules":_vm.rules}},[_c('a-form-model-item',{attrs:{"label":"名称","label-col":_vm.labelCol,"prop":"name","wrapper-col":_vm.wrapperCol}},[_c('a-input',{attrs:{"max-length":128},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('a-form-model-item',{attrs:{"label":"描述","label-col":_vm.labelCol,"prop":"description","wrapper-col":_vm.wrapperCol}},[_c('a-textarea',{attrs:{"max-length":255},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1),_c('a-divider'),_c('a-form-model-item',{attrs:{"prop":"content"}},[_c('Toolbar',{staticStyle:{"border-bottom":"1px solid #ccc"},attrs:{"default-config":_vm.toolbarConfig,"editor":_vm.editor}}),_c('Editor',{staticStyle:{"height":"480px","overflow-y":"hidden"},attrs:{"default-config":_vm.editorConfig},on:{"onChange":_vm.onChange,"onCreated":_vm.onCreated}})],1)],1),_c('div',{style:({
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #e9e9e9',
      padding: '10px 16px',
      background: '#fff',
      textAlign: 'right',
      zIndex: 1,
    })},[_c('a-space',{attrs:{"size":"middle"}},[_c('a-button',{on:{"click":_vm.closeDrawer}},[_vm._v("取消")]),_c('a-button',{attrs:{"loading":_vm.loading,"type":"primary"},on:{"click":_vm.ok}},[_vm._v(" 确定 ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }